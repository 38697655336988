@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

$font-bold: 700 !important;
$font-semibold: 600 !important;
$font-medium: 500 !important;
$font-regular: 400 !important;
$font-light: 300 !important;
$font-poppins: "Poppins", sans-serif !important;

.font-family-poppins-bold {
  font-family: $font-poppins;
  font-weight: $font-bold;
}

.font-family-poppins-semibold {
  font-family: $font-poppins;
  font-weight: $font-semibold;
}

.font-family-poppins-medium {
  font-family: $font-poppins;
  font-weight: $font-medium;
}

.font-family-poppins-regular {
  font-family: $font-poppins;
  font-weight: $font-regular;
}

.font-family-poppins-light {
  font-family: $font-poppins;
  font-weight: $font-light;
}
