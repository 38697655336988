@charset "UTF-8";
@import './assets/scss/colors.scss';
@import './assets/scss/fonts.scss';

*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  line-height: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  overflow-x: hidden !important;
}

.back-to-top {
  margin: 0 !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 1000 !important;
}

.bg-dark {
  background: $color-dark !important;
}

.bg-dark-medium {
  background: $color-dark-medium !important;
}

.bg-dark-regular {
  background: $color-dark-regular !important;
}

.bg-dark-light {
  background: $color-dark-light !important;
}

.bg-white {
  background: $color-white !important;
}

.bg-secondary {
  background: $color-secondary !important;
}

.bg-light {
  background: $color-light !important;
}

.bg-primary {
  background: $color-primary !important;
}

.bg-primary-light {
  background: $color-primary-light !important;
}

.bg-info {
  background: $color-info !important;
}

.bg-danger {
  background: $color-danger !important;
}

.bg-danger-light {
  background: $color-danger-light !important;
}

.bg-success {
  background: $color-success !important;
}

.bg-success-light {
  background: $color-success-light !important;
}

.bg-grey {
  background-color: $color-grey;
}

.btn-dark {
  background: $color-dark !important;
  border-color: $color-dark !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-outline-dark {
  background: $color-white !important;
  border: 2px $color-dark solid !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-dark !important;
  height: 50px !important;
  text-transform: initial !important;

  &:hover {
    background: $color-dark-regular !important;
    border: 2px $color-dark-regular solid !important;
    color: $color-white !important;
  }
}

.btn-dark-medium {
  background: $color-dark-medium !important;
  border-color: $color-dark-medium !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-dark-regular {
  background: $color-dark-regular !important;
  border-color: $color-dark-regular !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-dark-light {
  background: $color-dark-light !important;
  border-color: $color-dark-light !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-outline-dark-light {
  background: $color-white !important;
  border: 2px $color-dark-light solid !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-dark-light !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-grey {
  background: $color-grey !important;
  border-color: $color-grey !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-dark !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-white {
  background: $color-white !important;
  border-color: $color-white !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-dark !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-secondary {
  background: $color-secondary !important;
  border-color: $color-secondary !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-light {
  background: $color-light !important;
  border-color: $color-light !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-primary {
  background: $color-primary !important;
  border-color: $color-primary !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-outline-primary {
  background: $color-white !important;
  border: 2px $color-primary solid !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-primary !important;
  height: 50px !important;
  text-transform: initial !important;

  &:hover {
    background: $color-primary !important;
    color: $color-white !important;
  }
}

.btn-info {
  background: $color-info !important;
  border-color: $color-info !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-danger {
  background: $color-danger !important;
  border-color: $color-danger !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-success {
  background: $color-success !important;
  border-color: $color-success !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-white !important;
  height: 50px !important;
  text-transform: initial !important;
}

.btn-outline-success {
  background: $color-white !important;
  border: 2px $color-success solid !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: $color-success !important;
  height: 50px !important;
  text-transform: initial !important;

  &:hover {
    background: $color-success !important;
    color: $color-white !important;
  }
}

.border-bottom-r-l {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.container-top {
  margin-top: 70px;
}

.cursor-pointer {
  cursor: pointer;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  cursor: pointer;
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  cursor: pointer;
  color: #1b1b1b;
  background-color: #f0f1f4;
  border: transparent;
  line-height: 1.9;
  font-weight: 400;
}

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-file-label {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 45px;
  padding: 5px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(231.63, 228.73, 228.73, 0.87);
  border: 1px solid rgba(231.63, 228.73, 228.73, 0.87);
  border-radius: 8px;
}

.custom-file-label::after {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 3;
  display: block;
  padding: 7px 15px;
  line-height: 1.5;
  color: #FFF;
  background-color: #ff7800;
  content: "Abrir" !important;
  border-left: inherit;
  height: 35px;
  border-radius: 5px;
}

.btn,
.card,
select.form-control,
textarea.form-control,
.form-control {
  border-radius: 10px !important;
}

.card {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19) !important;
  padding: 1rem;
  border-radius: .6rem !important;
}

.card-header {
  border-radius: .6rem .6rem 0 0 !important;
}

.card-footer {
  border-radius: 0 0 .6rem .6rem !important;
}

.form-container {
  height: 100vh;
  margin: 5% auto;
  max-width: 95%;
  width: 400px;
}

.modal-header,
.modal-body,
.modal-footer,
.card .card-header,
.card .card-footer,
.card .card-body {
  margin: 0 !important;
  padding: 1rem;
}

.modal-footer,
.card-footer {
  border: none !important;
}

select.form-control,
textarea.form-control,
.form-control {
  border: 2px $color-grey-light solid !important;
  box-shadow: none !important;
  color: $color-dark !important;
  font-size: 1rem !important;
  font-weight: normal;
  outline: none !important;

  &.active,
  &:active,
  &:focus,
  &:hover {
    border: 2px $color-primary solid !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

select.form-control,
.form-control {
  height: 45px !important;
}

textarea.form-control {
  height: 120px;
  resize: none;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.has-error-content {
  background-color: $color-grey;
  border-color: $color-danger;
  border-left: 4px solid;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  color: $color-danger;
  font-size: 16px;
  min-height: 40px;
  padding: 12px 10px;
  position: relative;
  width: 100%;
}

.has-error-border {
  border: 1px solid $color-danger;
}

.icon-close-modal {
  position: absolute !important;
  right: 8px;
  top: 8px;
}

.list-group {
  border-radius: 0 !important;
  border: none !important;
}

.list-group .badge {
  border-radius: 5px !important;
  height: 30px !important;
  padding: 5px;
  width: 30px !important;
}

.list-group .badge img {
  width: 20px !important;
}

.modal .modal-content .modal-header .btn-close {
  background: $color-white !important;
  border-radius: 50%;
  color: $color-dark !important;
  height: 20px;
  opacity: 1;
  position: absolute;
  right: -7px;
  top: -7px;
  width: 20px;
}

.navbar {
  box-shadow: 0 0 10px 7px rgba(0, 0, 0, 0.05) !important;
  border: none !important;
  height: 70px !important;
  width: 100%;
  z-index: 1000;
}

.navbar-toggler {
  box-shadow: none !important;
  border: none !important;
  padding: 5px;
  height: 40px;
  width: 45px;
  z-index: 1000;
}

.navbar-toggler > img {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  top: -2px;
  vertical-align: middle;
  width: 35px !important;
}

.navbar .navbar-logo {
  display: block;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  width: calc(100% - 25px);
}

.navbar .navbar-logo .img-logo {
  width: 150px;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: .75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: .75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

div.sidebar {
  background: $color-white !important;
  box-shadow: 8px 0 27px rgba(0, 0, 0, 0.13);
  left: -250px;
  height: 100vh;
  position: fixed;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  top: 0;
  width: 250px;
  z-index: 2000;
}

div.sidebar.expand {
  left: 0 !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

div.sidebar header {
  background: $color-white !important;
  box-shadow: 0 11px 14px rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 0;
  height: 50px;
  width: 100%;
}

div.sidebar header .close-sidedar {
  background: $color-white !important;
  box-shadow: none !important;
  border: none !important;
  float: left;
  height: 40px;
  margin: 5px 0 5px 5px;
  padding: 7px;
  position: relative;
  width: 40px;
  z-index: 100;
}

div.sidebar header .close-sidedar img {
  height: 16px !important;
  margin: 0 auto;
  position: relative;
  vertical-align: middle;
  width: 16px !important;
}

div.sidebar header .title-sidebar {
  display: inline;
  margin-left: 40px;
  padding: 5px;
  height: 50px !important;
  position: relative;
}

div.sidebar .container-sidebar {
  background: $color-white !important;
}

#scrollTop {
  background: $color-primary !important;
  box-shadow: none !important;
  border-radius: 50%;
  border-color: $color-primary !important;
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: none;
  right: 20px;
  bottom: 30px;
  margin: 0;
  padding: 14px 8px;
  overflow: hidden;
  position: fixed;
  z-index: 1000;
}

#scrollTop img {
  height: auto;
  display: flex;
  margin: 0 auto;
  padding: 0;
  position: relative;
  width: 20px;
}

.signature-pad {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}

.signature-pad {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 10px;
  width: 100%;
  height: 200px;
  background-color: #fff;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.19);
  border-radius: 20px;
  padding: 16px;
}

.signature-pad--body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.signature-pad--body canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}


.text-dark {
  color: $color-dark !important;
}

.text-dark-medium {
  color: $color-dark-medium !important;
}

.text-dark-regular {
  color: $color-dark-regular !important;
}

.text-dark-light {
  color: $color-dark-light !important;
}

.text-white {
  color: $color-white !important;
}

.text-secondary {
  color: $color-secondary !important;
}

.text-light {
  color: $color-light !important;
}

.text-primary {
  color: $color-primary !important;
}

.text-info {
  color: $color-info !important;
}

.text-danger {
  color: $color-danger !important;
}

.text-success {
  color: $color-success !important;
}

.text-justify {
  text-align: justify !important;
}

.text-inherit {
  text-transform: inherit !important;
}

.text-underline {
  cursor: pointer;
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

.text-no-underline {
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.text-underline-static {
  text-decoration: underline !important;
}

.swal2-modal {
  border-radius: 15px !important;
}

.photo-invent {
  max-height: 500px;
  max-width: 100%;
}

.photo-invent2 {
  max-height: 300px;
  max-width: 100%;
}

.photo-invent > img,
.photo-invent2 > img {
  margin: 0 auto;
  position: relative;
  height: 100%;
  object-fit: fill;
  width: 100%;
}

.headerTable {
  border: 1px solid transparent !important;
  border-radius: 15px !important;
}

.headerTableCol {
  width: 33% !important;
}

.bg-first {
  background: $color-primary !important;
  border-radius: 15px 0 0 15px !important;
}

.bg-first > div {
  color: #FFF !important;
}

.bg-second {
  background: $color-second !important;
}

.bg-three {
  background: $color-three !important;
  border-radius: 0 15px 15px 0 !important;
}

.bg-second > div,
.bg-three > div {
  color: #616264 !important;
}

.footerTableCol1 {
  width: 66% !important;
}

.footerTableCol2 {
  width: 28% !important;
}

.footerTableColNone {
  width: 2% !important;
}

@media all and (max-width: 992px) {
  .headerTableCol{
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 60px !important;
    white-space: normal!important;
    margin: 0 auto;
    text-align: center !important;
    width: 100% !important;
  }

  .bg-first {
    border-radius: 15px 15px 0 0 !important;
  }

  .bg-three {
    border-radius: 0 0 15px 15px !important;
  }

  .footerTableCol1,
  .footerTableCol2,
  .footerTableColNone {
    align-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    white-space: normal!important;
    margin: 0 auto;
    text-align: center !important;
    width: 100% !important;
  }
}

.swal2-container {
  z-index: 1360 !important;
}
