$color-white: #FFFFFF;
$color-dark: #181A20;
$color-dark-medium: #232323;
$color-dark-regular: #545454;
$color-dark-light: #77787C;
$color-secondary: #787575;
$color-grey: #EAECEE;
$color-grey-light: #D1CFCF;
$color-light: #F3F3F3;
$color-primary: #FD7017;
$color-primary-light: #FD701714;
$color-second: #FD701742;
$color-three: #FD70171A;
$color-info: #2176F0;
$color-danger: #FE2D2D;
$color-danger-light: #F3070714;
$color-success: #1FD35C;
$color-success-light: #1FD35C14;
